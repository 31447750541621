import { createContext, useContext, useMemo } from "react"
import { fetchRest } from "@lib/fetchRest"
import useSWR, { SWRConfiguration } from "swr"

export enum FEATURE_FLAGS {
    brokerStatus = "brokerStatus",
    showSubscription = "showSubscription"
}
const FeatureFlagContext = createContext({} as Record<FEATURE_FLAGS, boolean | undefined>)
const refreshInterval = 10 * 60 * 1000 // 10 minutes in milliseconds

async function loadFlags(key?: string) {
    const { data } = await fetchRest(
        "feature-flags",
        {
            fields: ["id", "featureName"],
            populate: {
                enabledUsernames: {
                    fields: ["value"]
                }
            },
            filters: {
                $or: [
                    {
                        enabledForAll: {
                            $eq: true
                        }
                    },
                    {
                        enabledUsernames: {
                            value: {
                                $eq: key ?? "anonymous"
                            }
                        }
                    }
                ]
            },
            pagination: {
                limit: 100
            }
        },
        undefined
    )
    const fetchedFlags = {} as Record<FEATURE_FLAGS, boolean | undefined>
    if (Array.isArray(data)) {
        data.forEach((item) => {
            if (item && typeof item.featureName === "string") {
                fetchedFlags[item.featureName] = true
            }
        })
    }

    return fetchedFlags
}

export function FeatureFlagProvider({ children, context, isSessionLoading }) {
    const { key, options } = useMemo(() => {
        return {
            key: isSessionLoading ? null : `featureFlags-${context.key}`,
            options: { refreshInterval, fetcher: () => loadFlags(context.key), revalidateOnFocus: false } as SWRConfiguration
        }
    }, [context.key, isSessionLoading])
    const { data: flags = {} } = useSWR(key, options)

    return <FeatureFlagContext.Provider value={flags || {}}>{children}</FeatureFlagContext.Provider>
}

export function useFlags() {
    return useContext(FeatureFlagContext)
}
