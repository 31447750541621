"use client"
import React, { useMemo } from "react"
import { useSessionContext } from "supertokens-auth-react/recipe/session"
import { FeatureFlagProvider } from "@contexts/FeatureFlagContext"

type UserContext = {
    kind: "user"
    key?: string
    anonymous?: true
    name?: string
    email?: string
    [additional: string]: any
}

export const FeatureFlagWrapper: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const session = useSessionContext()

    const flagContext = useMemo(() => {
        let context: UserContext = { kind: "user", anonymous: true }
        if (session.loading === false) {
            if (session.doesSessionExist) {
                context = {
                    kind: "user",
                    key: session.accessTokenPayload.username
                    // "name": "Anna",
                    // "email": "anna@globalhealthexample.com",
                    // "jobFunction": "doctor"
                    // Add any other user properties you need
                }
            }
        }
        return context
    }, [session])

    return (
        <FeatureFlagProvider context={flagContext} isSessionLoading={session.loading}>
            {children}
        </FeatureFlagProvider>
    )
}
