const apiMethod = (body: object) => {
    return fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/listing-popularities/update`, {
        method: "PUT",
        headers: {
            "User-Agent": "dvc-client"
        },
        body: JSON.stringify(body)
    })
}
export function trackListingView(id: number) {
    return apiMethod({
        id,
        views: "increase"
    })
}

export function trackListingFavorite(id: number, isFavorite: boolean) {
    return apiMethod({
        id,
        favorites: isFavorite ? "increase" : "decrease"
    })
}
