"use client"
import React, { useEffect, useMemo } from "react"
import style from "@css/modules/Layout.module.scss"
import { Layout, notification } from "antd"
import { store } from "@utils/store"
import { FooterComponent } from "./components/Footer"
import { fetchUserSearches, saveUserSearches, useUser } from "@lib/api/user"
import { likeStorageKey } from "../Listings/listings-page-utils"
import cn from "classnames"
import Router from "next/router"
import { usePathname, useRouter, useSelectedLayoutSegments } from "next/navigation"
import HeaderComponent from "./components/Header"

const { Content } = Layout
const pageClassMap = {
    "/": style.homePageRoot,
    "/resorts": style.resortsRoot,
    "/listings": style.listingsRoot
}
const segmentedClassMap = {
    "resorts chart": style.resortChartsRoot,
    "listings offer": style.offerRoot,
    "resorts listings": style.listingsRoot
}

function AppLayout(props) {
    const router = useRouter()
    const { user } = useUser()
    useEffect(() => {
        const currentValue = localStorage.getItem(likeStorageKey) || "{}"
        const val = JSON.parse(currentValue)
        store.set(store.k.favorites, val)
        let method
        //console.log(Router.beforePopState)
        Router._bps = () => {
            method = "POP"
            return true
        }
        const handler = () => {
            store.set(store.k.routeMethod, method || "PUSH")
            method = undefined
        }
        Router.events.on("routeChangeStart", handler)
        return () => {
            Router.events.off("routeChangeStart", handler)
        }
    }, [])
    const segments = useSelectedLayoutSegments()
    const pathname = usePathname()

    useEffect(() => {
        if (user && user.emails) {
            klaviyo
                .identify({
                    email: user.emails[0],
                    $first_name: user.first_name,
                    $last_name: user.last_name
                })
                .then((dd) => {
                    console.log("identify", dd)
                })
                .catch(() => {
                    console.log("klaviyo identify failed")
                })
            const savedSearch = localStorage.getItem("savedSearchBeforeLogin")
            localStorage.removeItem("savedSearchBeforeLogin")
            if (savedSearch) {
                console.log("restore save search")
                try {
                    const { body, date } = JSON.parse(savedSearch)
                    // 15 minutes
                    if (date && date > Date.now()) {
                        saveUserSearches(body).then(async (res) => {
                            if (res?.data?.created_at) {
                                notification.open({
                                    type: "success",
                                    placement: "bottomRight",
                                    message: "Saved search created"
                                })
                                router.push("/redirect/listings?filter=" + res.data.created_at)
                            }
                            await fetchUserSearches()
                        })
                    }
                } catch (e) {
                    console.warn("Save search restore failed", e)
                }
            }
        }
    }, [user, router])
    const pageClass = useMemo(() => {
        if (pageClassMap[pathname]) {
            return pageClassMap[pathname]
        }
        const segmented = `${segments[0]} ${segments[2]}`
        if (segmentedClassMap[segmented]) {
            return segmentedClassMap[segmented]
        }
        return null
    }, [segments, pathname])
    return (
        <Layout className={cn(style.layout, pageClass)}>
            <HeaderComponent />
            <Content className={style.content + " site-layout"}>{props.children}</Content>
            <FooterComponent />
        </Layout>
    )
}

export default AppLayout
