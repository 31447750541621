"use client"
import { Children, cloneElement, useMemo } from "react"
import parse from "html-react-parser"
import { YouTube } from "../YouTube/YouTube"
import { MultipleResortChart } from "../Listings/Chart/MultipleResortChart"
import cn from "classnames"

const replaceElementsWithComponents = (element: any) => {
    if (Array.isArray(element)) {
        return element.map(replaceElementsWithComponents)
    }
    if (typeof element.type === "string") {
        switch (element.type) {
            case "oembed": {
                return <YouTube className={undefined} videoClassName={undefined} url={element.props.url} iframeHeight="500" />
            }
            case "chart": {
                return <MultipleResortChart {...element.props} />
            }
            default: {
                const newChildren = Children.map(element?.props?.children, (child) => replaceElementsWithComponents(child))
                return cloneElement(element, element.props, newChildren)
            }
        }
    }
    return element
}
export function CKEditor({ content, className }: { content: string; className?: string }) {
    const updatedHtml = useMemo(() => {
        const parsedHtml = parse(content)
        return replaceElementsWithComponents(parsedHtml)
    }, [content])

    return <div className={cn("ck-content", className)}>{updatedHtml}</div>
}
