import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "/vercel/path0/src/components/Animation/Carousel.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Animation/SlideAppear.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Block1"] */ "/vercel/path0/src/components/HomePage/Block1/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Block2"] */ "/vercel/path0/src/components/HomePage/Block2/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Block3"] */ "/vercel/path0/src/components/HomePage/Block3/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Blog"] */ "/vercel/path0/src/components/HomePage/Blog/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Subscribe"] */ "/vercel/path0/src/components/HomePage/Subscribe/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/css/modules/HomePage.module.scss");
