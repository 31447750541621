import qs from "qs"
import { waiter } from "@utils/index"
import { normalize } from "@lib/utils"

const _fetch = typeof fetch !== "undefined" ? fetch : require("node-fetch")

interface PaginationByPage {
    page: number
    pageSize: number
    withCount?: boolean
}

interface PaginationByOffset {
    start?: number
    limit: number
    withCount?: boolean
}

interface StrapiBaseRequestParams {
    fields?: Array<string>
    populate?: string | Array<string> | Record<string, unknown>
}

interface StrapiRequestParams extends StrapiBaseRequestParams {
    sort?: string | Array<string>
    pagination?: PaginationByOffset | PaginationByPage
    filters?: Record<string, unknown>
    publicationState?: "live" | "preview"
    // locale?: StrapiLocale
}

export interface StrapiMeta {
    pagination: {
        start: number
        limit: number
        total?: number
    }
}

export interface StrapiResponse<T> {
    data: T
    meta: StrapiMeta
}

export async function fetchRest<T extends any>(
    entry: string,
    query?: StrapiRequestParams,
    signal?: AbortSignal,
    revalidate = 300, // does not work client-side
    retry: boolean = false
): Promise<StrapiResponse<T>> {
    let res
    let json
    let text

    const queryString = qs.stringify(query, {
        //arrayFormat: "repeat", //would be nice to make it work
        encodeValuesOnly: true // prettify URL
    })
    const url = `${process.env.NEXT_PUBLIC_API_URL}/api/${entry}${queryString ? `?${queryString}` : ""}`
    if (url.length > 2048) {
        console.error("queryString is too long", queryString.length)
        //  throw new Error(entry + " api url too long")
    }
    try {
        res = await _fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "User-Agent": "dvc-client"
            },
            signal,
            next: { revalidate: revalidate }
        })
        text = await res.text()
        json = JSON.parse(text)
        if (!retry && json.errors) {
            console.error("strapi error", json.errors)
            console.error("entry", entry)
            console.error("query", JSON.stringify(query))
        }
    } catch (err) {
        if (signal?.aborted) {
            return Promise.reject("AbortError")
        }
        if (retry) {
            console.log("URL", `${process.env.NEXT_PUBLIC_API_URL}/api/${entry}?${queryString}`)
            if (err.name === "AbortError") {
                throw err
            } else {
                console.log("res", res && res.status)
                console.log("json", json)
                console.log("text", text)
                console.log("query", query)
                throw err
            }
        } else {
            console.log("Retry rest api entry", entry)
            await waiter(1000)
            return fetchRest(entry, query, signal, revalidate, true)
        }
    }

    return { data: normalize(json.data), meta: json.meta }
}
