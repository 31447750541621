import { Badge, Spin } from "antd"
import { Like } from "@css/icons/Like"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import css from "@css/modules/Layout.module.scss"
import { getListingsByIds } from "@lib/api/listings"
import useSWR from "swr"
import { getTotalPrice, originImgURL, updateListingLike } from "../../Listings/listings-page-utils"
import Image from "next/image"
import { addCurrency, formatMonth } from "@utils/index"
import moment from "moment/moment"
import { Close } from "@css/icons/SVG"
import cn from "classnames"
import { getListingUrl } from "@utils/listingUrl"
import { Listing, ListingResponse } from "@t/Listing"
import { useRouter } from "next/navigation"
import { ListingStatus } from "@components/Listings/ListingStatus"
import { store } from "@utils/store"

export function Favorites() {
    const [open, setOpen] = useState(false)
    const [favorites, setFavorites] = useState({})
    const onHide = useCallback((e: MouseEvent) => {
        const target = e.target as HTMLElement
        if (!target.closest(".favorite-root")) {
            setOpen(false)
            document.body.removeEventListener("click", onHide)
        }
    }, [])
    const onShow = useCallback(() => {
        setOpen(true)
        document.body.addEventListener("click", onHide)
    }, [])
    useEffect(() => {
        setFavorites(store.get(store.k.favorites) || {})
        store.watch(store.k.favorites, (f) => {
            setFavorites(f)
        })
        return () => {
            document.body.removeEventListener("click", onHide)
        }
    }, [])
    const f = useMemo(() => {
        return Object.keys(favorites || {}).filter((i) => favorites[i])
    }, [favorites])

    const { data, isLoading } = useSWR<ListingResponse>(open ? ["getListingsByIds" + f.join(","), f] : null, {
        fetcher: getListingsByIds,
        dedupingInterval: 0,
        keepPreviousData: true
    })
    const ref = useRef<HTMLDivElement>(null)
    const items = useMemo(() => {
        if (!data?.listings.length) {
            return []
        }
        const orders = f.reduce((a, b) => {
            a[b] = favorites[b] || 0
            return a
        }, {})
        data?.listings.sort((a, b) => orders[b.id] - orders[a.id])
        return data?.listings
    }, [data, f, favorites])
    const onRemove = (i: any, e: MouseEvent) => {
        e.stopPropagation()
        updateListingLike({
            ...i,
            like: false
        })
    }
    const router = useRouter()
    const openListing = (i: Listing) => {
        router.push(getListingUrl(i), { scroll: true })
    }
    return (
        <div className={cn(css.favoritesRoot, "favorite-root")} ref={ref}>
            <button className={css.overBadge} onClick={onShow} aria-label="view favorites">
                <Badge size="small" count={f.length} color={"#CC78FF"} className={css.favoritesBadge}>
                    <Like className={css.favorites} width={22} height={22} fill={f.length ? "#CC78FF" : "transparent"} />
                </Badge>
            </button>
            {open && (
                <div className={css.favoritesContent}>
                    <div className={css.scrollContent} autoFocus>
                        {items.map((j) => {
                            return (
                                <div className={css.cardBody} key={j.id} onClick={() => openListing(j)}>
                                    <div className={css.cardHeader}>
                                        <span className={css.cardHeaderId}>
                                            <span>{j.resort?.short_name}</span> ID: {j.id}
                                        </span>
                                        {originImgURL[j.origin] && (
                                            <Image className={css.originImg} alt={j.origin} src={originImgURL[j.origin]} title={j.origin} width="18" height="18" />
                                        )}
                                        {/* {renderStatus(j)}*/}
                                    </div>
                                    <ul className={css.cardPropsList}>
                                        <li>
                                            Status <ListingStatus listing={j} className={css.status} />
                                        </li>
                                        <li>
                                            Points <span>{j.points_on_contract}</span>
                                        </li>
                                        <li>
                                            Price per Pts. <span>{addCurrency(j.price_per_point)}</span>
                                        </li>
                                        <li>
                                            Month <span>{j ? formatMonth(j.use_month) : ""}</span>
                                        </li>
                                        <li>
                                            Price <span>{addCurrency(getTotalPrice(j))}</span>
                                        </li>
                                        <li>
                                            Age <span>{moment(j.parsed_date).fromNow(true)}</span>
                                        </li>
                                    </ul>
                                    <Close className={css.remove} onClick={(e) => onRemove(j, e)} />
                                </div>
                            )
                        })}
                        {!items.length && !isLoading && <div className={css.noFavorites}>No favorites</div>}
                        {isLoading && <Spin className={css.loadingSpin} tip="Loading..." size="default" />}
                    </div>
                </div>
            )}
        </div>
    )
}
