"use client"
import { usePathname, useRouter } from "next/navigation"
import { frontendConfig, setRouter } from "config/frontendConfig"
import SuperTokensReact, { SuperTokensWrapper } from "supertokens-auth-react"
import { ReactNode } from "react"

if (typeof window !== "undefined") {
    // we only want to call this init function on the frontend, so we check typeof window !== 'undefined'
    SuperTokensReact.init(frontendConfig())
}
export function SuperTokenInit({ children }: { children: ReactNode }) {
    const pathname = usePathname()
    setRouter(useRouter(), pathname, typeof window !== "undefined" ? new URLSearchParams(window.location.search) : new URLSearchParams())
    return <SuperTokensWrapper>{children}</SuperTokensWrapper>
}
