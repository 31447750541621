import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\",\"300\",\"500\"],\"subsets\":[\"latin\"],\"fallback\":[\"sans-serif\"],\"display\":\"swap\",\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Lora\",\"arguments\":[{\"weight\":[\"500\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"serif\"],\"variable\":\"--font-lora\"}],\"variableName\":\"lora\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/antd/dist/antd.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/src/app/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SuperTokenInit"] */ "/vercel/path0/src/app/SuperTokenInit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Clarity"] */ "/vercel/path0/src/components/Clarity.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeatureFlagWrapper"] */ "/vercel/path0/src/components/FeatureFlagWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GAPageView"] */ "/vercel/path0/src/components/GAPageView.js");
;
import(/* webpackMode: "eager", webpackExports: ["GeneralData"] */ "/vercel/path0/src/components/GeneralData/GeneralData.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Layout/Layout.js");
;
import(/* webpackMode: "eager", webpackExports: ["RouterHistory"] */ "/vercel/path0/src/components/RouterHistory.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/css/main.scss");
