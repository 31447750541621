"use client"
import PropsTypes from "prop-types"
import cn from "classnames"
import React from "react"

export class Carousel extends React.Component {
    static propTypes = {
        onChange: PropsTypes.func,
        classNames: PropsTypes.shape({
            itemNext2: PropsTypes.string,
            itemPrev2: PropsTypes.string,
            dots: PropsTypes.shape({
                root: PropsTypes.string,
                active: PropsTypes.string,
                item: PropsTypes.string
            }),
            item: PropsTypes.string,
            itemActive: PropsTypes.string,
            itemNext: PropsTypes.string,
            itemPrev: PropsTypes.string,
            root: PropsTypes.string
        }),
        prevBtn: PropsTypes.func,
        nextBtn: PropsTypes.func,
        startAt: PropsTypes.number,
        autoplayTime: PropsTypes.number,
        activeSlide: PropsTypes.number,
        items: PropsTypes.array
    }

    constructor(props) {
        super(props)
        const [prevIndex, nextIndex] = this.getNextPrev(props.startAt)
        const [_, next2] = this.getNextPrev(nextIndex)
        const [prev2, __] = this.getNextPrev(prevIndex)
        this.state = {
            prevIndex,
            nextIndex,
            next2,
            prev2,
            items: props.items,
            activeIndex: props.startAt
        }

        /**
         * @type {React.RefObject<HTMLDivElement>}
         */
        this.rootRef = React.createRef()
    }

    componentDidMount() {
        if (this.props.autoplayTime) {
            this.autoplay()
        }
        this.props.onChange && this.props.onChange(this.state.activeIndex)
        this.observer = new ResizeObserver(this.onObserve)
        this.elements = Array.from(this.rootRef.current.getElementsByClassName(this.props.classNames.item))
        this.elements.forEach((i) => this.observer.observe(i))
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.activeIndex !== this.state.activeIndex) {
            this.props.onChange && this.props.onChange(this.state.activeIndex)
        }
        if (prevProps.activeSlide !== this.props.activeSlide) {
            this.goTo(this.props.activeSlide)
        }
        if (prevProps.items !== this.props.items) {
            this.elements.forEach((i) => this.observer.unobserve(i))
            this.elements = Array.from(this.rootRef.current.getElementsByClassName(this.props.classNames.item))
            this.elements.forEach((i) => this.observer?.observe(i))
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
        this.observer?.disconnect()
    }

    /**
     * @param entries {ResizeObserverEntry[]}
     */
    onObserve = (entries) => {
        const maxHeight = Math.max(0, ...entries.map((i) => i?.borderBoxSize?.[0]?.blockSize))
        this.rootRef.current.style.height = maxHeight + "px"
    }

    autoplay = () => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            if (!document.hidden) {
                this.go(1)
            }
            this.autoplay()
        }, this.props.autoplayTime || 10000)
    }

    getNextPrev = (activeIndex) => {
        const itemsLength = this.props.items.length
        let prev = activeIndex - 1
        let next = activeIndex + 1
        if (activeIndex === 0) {
            prev = itemsLength - 1
        }
        if (activeIndex === itemsLength - 1) {
            next = 0
        }
        return [prev, next]
    }

    go = (forEl) => {
        const oldIndex = this.state.activeIndex
        const itemsLength = this.props.items.length
        let activeIndex = oldIndex + forEl
        if (activeIndex < 0) {
            activeIndex = itemsLength - 1
        }
        if (activeIndex === itemsLength) {
            activeIndex = 0
        }
        const [prevIndex, nextIndex] = this.getNextPrev(activeIndex)
        const [_, next2] = this.getNextPrev(nextIndex)
        const [prev2, __] = this.getNextPrev(prevIndex)
        this.setState({
            activeIndex,
            prevIndex,
            next2,
            prev2,
            nextIndex
        })
    }

    onMouseLeave = () => {
        if (this.props.autoplayTime) {
            this.autoplay()
        }
    }

    onMouseEnter = () => {
        clearTimeout(this.timer)
    }

    goTo = (activeIndex) => {
        if (this.state.activeIndex !== activeIndex) {
            const [prevIndex, nextIndex] = this.getNextPrev(activeIndex)
            const [_, next2] = this.getNextPrev(nextIndex)
            const [prev2, __] = this.getNextPrev(prevIndex)
            this.setState({
                activeIndex,
                prevIndex,
                next2,
                prev2,
                nextIndex
            })
        }
    }

    touchStart

    onTouchStart = (ev) => {
        clearTimeout(this.timer)
        const key = Number(ev.currentTarget.dataset.key)
        if (key === this.state.activeIndex) {
            this.touchStart = ev.clientX || (ev.touches[0] || ev.changedTouches[0]).clientX
        } else {
            this.goTo(key)
            this.touchStart = null
        }
    }

    onTouchEnd = (ev) => {
        this.props.autoplayTime && this.autoplay()
        if (!this.touchStart) return
        const x = ev.clientX || (ev.touches[0] || ev.changedTouches[0]).clientX
        const diff = this.touchStart - x
        if (diff < -100) {
            this.go(-1)
        } else if (diff > 100) {
            this.go(1)
        }
        this.touchStart = null
    }

    render() {
        const { classNames, items, prevBtn, nextBtn } = this.props
        const { activeIndex, prevIndex, nextIndex, next2, prev2 } = this.state
        return (
            <div className={classNames.root} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} ref={this.rootRef}>
                {items.map((i, k) => {
                    const className = cn({
                        [classNames.itemActive]: k === activeIndex,
                        [classNames.itemNext]: k === nextIndex,
                        [classNames.itemNext2]: classNames.itemNext2 && k === next2,
                        [classNames.itemPrev]: k === prevIndex,
                        [classNames.itemPrev2]: classNames.itemPrev2 && k === prev2,
                        [classNames.item]: true
                    })
                    return (
                        <div
                            key={k}
                            data-key={k}
                            className={className}
                            onMouseDown={this.onTouchStart}
                            onTouchStart={this.onTouchStart}
                            onTouchEnd={this.onTouchEnd}
                            onMouseUp={this.onTouchEnd}>
                            {i}
                        </div>
                    )
                })}

                {classNames.dots && (
                    <div className={classNames.dots.root}>
                        {items.map((i, k) => {
                            const className = cn({
                                [classNames.dots.active]: k === activeIndex,
                                [classNames.dots.item]: true
                            })
                            return <div key={k} className={className} onClick={() => this.goTo(k)} />
                        })}
                    </div>
                )}
                {prevBtn && prevBtn(() => this.go(-1))}
                {nextBtn && nextBtn(() => this.go(1))}
            </div>
        )
    }
}
